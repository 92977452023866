.mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #373737;
  background-color: #37373799;
  height: 100%;
  z-index: 1500;
  filter: alpha(opacity=50);
}

.r-coupon {
  width: calc(400px);
  color: #000;
  position: relative;
  margin: 15vh auto 0;
  .r-header {
    text-align: center;
    padding: 20px 0;
    font-size: 13px;
    width: calc(400px);
    height: calc(400px * 0.34286);
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-repeat: no-repeat;
    .r-title {
      font-size: 24px;
      margin-bottom: 1px;
    }
    .r-tips {
      font-size: 24px;
    }
  }
  .r-content {
    background-color: #008deb;
    padding: 1px 1px 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 270px;
    padding: 10px;
  }
  .r-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 3px;
    padding: 14px 10px 14px 0;
    .left {
      width: 92px;
      height: 40px;
      text-align: center;
      color: #f51b1b;
      font-size: 14px;
      border-right: 1px solid #ebebeb;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 40px;
      padding: 0 5px;
      span {
        font-size: 22px;
      }
    }
    .right {
      flex: 1;
      font-size: 14px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 10px;
      .rules {
        display: flex;
        justify-content: space-between;
        color: #333;
      }
      .time {
        margin-top: 6px;
        color: #a3a3a3;
      }
    }
  }
  .r-bottom {
    .r-bottom-box {
      background-image: url('../img/button.png');
      width: calc(400px);
      height: calc(400px * 0.2143);
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #f51b1b;
      font-size: 14px;
    }
  }
}
.r-close-img {
  width: 43px;
  height: 43px;
  position: absolute;
  bottom: -60px;
  right: 45%;
}
